<template>
  <div class="input-group">
    <input
      type="text"
      class="form-control"
      v-on:input="changeValue($event.target.value)"
      v-bind:value="address"
    />
    <div class="input-group-append">
      <button
        type="button"
        @click="showMap"
        class="btn btn-primary dropdown-toggle no-caret"
      >
        <i class="fab fa-periscope m-r-5"></i>选择定位
      </button>
    </div>

    <modal
      name="mapModal"
      transition="pop-out"
      :width="800"
      :focus-trap="true"
      :height="600"
    >
      <div class="card border-0">
        <div class="card-header f-w-600">请选择企业所在的座标</div>
        <div class="card-body height-500 m-0 p-0">
          <div class="searchInput" id="pickerBox">
            <input
              class="form-control"
              id="pickerInput"
              placeholder="输入关键字选取地点"
            />
          </div>
          <div id="containerMap" class="mapAdss height-500" tabindex="0"></div>
        </div>
        <div class="card-footer text-right f-w-600">
          <a @click="onOK" class="btn btn-sm btn-primary m-r-5"> 确定 </a>
          <button
            type="button"
            @click="$modal.hide('mapModal')"
            class="btn btn-sm btn-default"
          >
            ❌关闭
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
const MODAL_WIDTH = 656;
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  name: "mapInput",
  //   props: {
  //     coordinateMain: {
  //       type: String,
  //       default: "",
  //     },
  //     address: {
  //       type: String,
  //       default: "",
  //     },
  //   },
  data() {
    return {
      id: "",
      windowHeight: "",
      mapMain: null,
      lnglatMain: [],
      markerMain: null,
      infoWindowMain: null,
      address: "",
      coordinate: [],
      coordinateMain: "",
      //   text:""
      //   modalWidth: MODAL_WIDTH,
    };
  },
  created() {
    this.modalWidth =
      window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH;
    // this.address1 = this.address;
    // console.log(this.address, this.address1);
  },
  mounted() {},
  methods: {
    showMap() {
      this.$modal.show("mapModal");
      this.createMap();
    },
    onOK() {
      // this.sMap.destroy();
      // this.$modal.close();
      this.$emit("charge", this.address, this.coordinate);
      this.$modal.hide("mapModal");
      // this.$destroy();
    },

    showAddsMap() {},
    //异步接收值
    initAdds(e) {
      this.address = e.address;
      this.coordinate = e.coordinate;
    },
    //修改内容并返回值
    changeValue(value) {
      this.address = value;
      this.$emit("charge", this.address, this.coordinate);
      // this.$destroy();
    },

    createMap() {
      let that = this;
      AMapLoader.load({
        key: "5794a2a043de715b4d37dd92f0ba64b6", // key
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Geocoder"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: "1.1", // AMapUI 缺省 1.1
          plugins: ["overlay/SimpleMarker"], // 需要加载的 AMapUI ui插件
        },
        Loca: {
          // 是否加载 Loca， 缺省不加载
          version: "1.3.2", // Loca 版本，缺省 1.3.2
        },
      })
        .then((SMap) => {
          if (that.sMap != undefined) {
            that.sMap.destroy();
          }
          that.sMap = new SMap.Map("containerMap", {
            zoom: 12,
            center: [121.651287, 29.771813], //初始化地图中心点
            pitch: 40,
          });
          that.markerMain = new SMap.Marker();
          that.infoWindowMain = new SMap.InfoWindow({
            offset: new SMap.Pixel(0, -20),
          });
          if (this.coordinateMain !== "") {
            let zuobiao = this.coordinateMain.split(",");

            this.lnglatMain = [zuobiao[0], zuobiao[1]];
            var addressName = ""; // 地址名称
            // 查询坐标信息
            SMap.service("amap.geocoder", function () {
              // 这里通过高德 SDK 完成。
              var geocoder = new SMap.Geocoder({});
              geocoder.getAddress(
                [zuobiao[0], zuobiao[1]],
                function (status, result) {
                  if (result.info === "OK") {
                    // 获得了有效的地址信息:
                    // console.log(result)
                    addressName = result.regeocode.formattedAddress;
                  }
                  that.address = addressName;
                  // 添加info提醒
                  that.markerMain.setMap(that.sMap);
                  that.infoWindowMain.setMap(that.sMap);
                  that.markerMain.setPosition(that.lnglatMain);
                  that.infoWindowMain.setPosition(that.lnglatMain);
                  that.infoWindowMain.setContent(
                    "地址信息 <div> 坐标：" +
                      zuobiao[0] +
                      zuobiao[1] +
                      "</div><div> 地址：" +
                      addressName +
                      "</div>"
                  );
                  that.infoWindowMain.open(
                    that.sMap,
                    that.markerMain.getPosition()
                  );
                  that.infoWindowMain.open(
                    that.sMap,
                    that.markerMain.getPosition()
                  );
                  that.sMap.setCenter(that.markerMain.getPosition());
                }
              );
            });
            if (that.markerMain !== null) {
              that.sMap.remove(that.markerMain);
            }

            that.markerMain = new SMap.Marker({
              draggable: true, // 是否允许拖动
              position: that.lnglatMain, // 覆盖物位置坐标
            });
            that.sMap.add(that.markerMain);
          }

          // 绑定地图组件内单击事件
          that.sMap.on("click", (e) => {
            this.lnglatMain = [e.lnglat.lng, e.lnglat.lat];
            var addressName = ""; // 地址名称
            // 查询坐标信息
            SMap.service("amap.geocoder", function () {
              // 这里通过高德 SDK 完成。
              var geocoder = new SMap.Geocoder({});
              geocoder.getAddress(
                [e.lnglat.lng, e.lnglat.lat],
                function (status, result) {
                  if (result.info === "OK") {
                    // 获得了有效的地址信息:
                    // console.log(result)
                    addressName = result.regeocode.formattedAddress;
                  }
                  that.address = addressName;
                  that.coordinate = that.lnglatMain.toString();
                  // 添加info提醒
                  that.markerMain.setMap(that.sMap);
                  that.infoWindowMain.setMap(that.sMap);
                  that.markerMain.setPosition(that.lnglatMain);
                  that.infoWindowMain.setPosition(that.lnglatMain);
                  that.infoWindowMain.setContent(
                    "地址信息 <div> 坐标：" +
                      e.lnglat.lng +
                      e.lnglat.lat +
                      "</div><div> 地址：" +
                      addressName +
                      "</div>"
                  );
                  that.infoWindowMain.open(
                    that.sMap,
                    that.markerMain.getPosition()
                  );
                  that.infoWindowMain.open(
                    that.sMap,
                    that.markerMain.getPosition()
                  );
                  that.sMap.setCenter(that.markerMain.getPosition());
                }
              );
            });
            if (that.markerMain !== null) {
              that.sMap.remove(that.markerMain);
            }

            that.markerMain = new SMap.Marker({
              draggable: true, // 是否允许拖动
              position: that.lnglatMain, // 覆盖物位置坐标
            });
            that.sMap.add(that.markerMain);
          });

          // eslint-disable-next-line no-undef
          AMapUI.loadUI(["misc/PoiPicker"], function (PoiPicker) {
            var poiPicker = new PoiPicker({
              input: "pickerInput",
            });

            // 初始化poiPicker
            window.poiPicker = poiPicker;
            // 选取了某个POI
            poiPicker.on("poiPicked", function (poiResult) {
              //  console.log(poiResult)
              var source = poiResult.source;
              var poi = poiResult.item;
              that.lnglatMain = [poi.location.lng, poi.location.lat];
              that.address = poi.district + poi.address;
              var info = {
                source: source,
                id: poi.id,
                name: poi.name,
                location: poi.location.toString(),
                address: poi.address,
              };
              that.markerMain.setMap(that.sMap);
              that.infoWindowMain.setMap(that.sMap);
              that.markerMain.setPosition(poi.location);
              that.infoWindowMain.setPosition(poi.location);
              that.infoWindowMain.setContent(
                "地址信息 <div> 名称：" +
                  info.name +
                  "</div><div> 坐标：" +
                  info.location +
                  "</div><div> 地址：" +
                  info.address +
                  "</div>"
              );
              that.infoWindowMain.open(
                that.sMap,
                that.markerMain.getPosition()
              );
              that.infoWindowMain.open(
                that.sMap,
                that.markerMain.getPosition()
              );
              that.sMap.setCenter(that.markerMain.getPosition());
            });

            // poiPicker.onCityReady(function () {
            //   // poiPicker.suggest("企业");
            // });
          });
        })
        .catch((e) => {
          console.error(e); // 加载错误提示
        });
    },
  },
};
</script>
<style>
#containerMap {
  left: 0px;
  top: 0px;
}
.searchInput {
  position: absolute;
  top: 80px;
  left: 30px;
  width: 200px;
  z-index: 1;
}
/* 去除高德地图logo */
.amap-logo {
  display: none;
  opacity: 0 !important;
}
.amap-copyright {
  opacity: 0;
}
.mapAdss {
  left: 0px;
  top: 50px;
}
</style>
