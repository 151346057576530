<template>
  <div class="w-auto">
    <!-- 如果页面组件:show属性为false页面显示为<lable></lable>组件否则为下拉框 -->
    <label v-if="show">{{ dictName }}</label>
    <v-select
      v-else
      v-model="id"
      label="label"
      :reduce="(options) => options.id"
      :options="options"
      @input="satusSelect"
    ></v-select>
  </div>
</template>

<script>
import { dictApi } from "@/api/system/dictApi";
// import { businessApi } from "@/api/system/businessApi";

export default {
  //组件名称
  name: "dictSelect",
  props: {
    value: {
      type: String,
      default: "",
    },
    pid: {
      type: String,
      default: "",
    },
    bChild: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // selected:{},
      options: [],
      id: "",
      dictName: "",
    };
  },
  mounted() {
    // console.log('Dict',this.Pid);
    if (!this.show) {
      this.GetData();
    }
    // if (this.pid == "公司") {
    //   this.GetCompanyData();
    // }
  },
  //设置默认值
  watch: {
    value: {
      deep: true,
      handler(v) {
        this.id = v;
        if (this.show) {
          this.GetDictById(this.id);
        }
        // console.log('入参改变了',v)
      },
    },
  },
  methods: {
    //基于Pid加载所有选择项
    GetData() {
      let that = this;
      dictApi
        .getDictUrl({
          id: that.pid,
          bChild: that.bChild,
        })
        .then((res) => {
          that.options = [];
          res.data.forEach((element) => {
            that.options.push({
              id: element.id,
              label: element.name,
            });
          });

          console.log(res);
        })
        .finally(() => {});
    },

    //企业名称选择组件
    // GetCompanyData() {
    //   let that = this;
    //   businessApi
    //     .getDictUrl({
    //       id: that.pid,
    //       bChild: that.bChild,
    //     })
    //     .then((res) => {
    //       that.options = [];
    //       res.data.forEach((element) => {
    //         that.options.push({
    //           id: element.id,
    //           label: element.name,
    //         });
    //       });

    //       console.log(res);
    //     })
    //     .finally(() => {});
    // },
    GetDictById(id) {
      let that = this;
      that.dictName = "";
      if (id) {
        dictApi
          .getDict({
            id: id,
          })
          .then((res) => {
            // console.log(res);
            if (res != undefined && res.success && res.data != undefined) {
              that.dictName = res.data.name;
            }
          })

          .finally(() => {});
      }
    },

    //设置默认值
    // setData(id) {
    //   console.log("dist setData", id);
    //   this.id = id;
    // },
    //选择后返回值
    satusSelect(v) {
      console.log("dist satusSelect", v);
      this.$emit("select", v);
    },
  },
};
</script>
