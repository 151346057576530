//数据字典处理
import {
    propertyService
} from '@/api/utils/request'

const apiUrl = {
    //根据pid获取下级树形列表
    getDictUrl: '/api/dictionary/getdict',
    //根据id获取数据字典信息
    getDict: '/api/dictionary/get',
    // 新增数据字典
    CreateDict: '/api/dictionary/add',
    //修改数据字典
    UpdateDictUrl: '/api/dictionary/update',
    DeleteDictUrl: '/api/dictionary/delete'
}

const dictApi = {
    // 登录(获取Toekn)
    //获取数据字典
    getDictUrl(params) {
        return propertyService({
            url: apiUrl.getDictUrl,
            method: 'get',
            params: params,
            needToken: true
        })
    },
    //新建数据字典
    CreateDict(parameter) {
        return propertyService({
            url: apiUrl.CreateDict,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },

    // 修改一个数据字典
    UpdateDictUrl(params) {
        return propertyService({
            url: apiUrl.UpdateDictUrl,
            method: 'post',
            data: params,
            needToken: true
        })
    },
    //删除一个数据字典
    DeleteDictUrl(params) {
        return propertyService({
            url: apiUrl.DeleteDictUrl,
            method: 'delete',
            params: params,
            needToken: true
        })
    },
    ////根据id获取数据字典信息
    getDict(params) {
        return propertyService({
            url: apiUrl.getDict,
            method: 'get',
            params: params,
            needToken: true
        })
    },
}

export {
    dictApi
}